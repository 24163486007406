import axios from "axios";
import { baseUrl } from "../utils/urls";
import { getAuthHeaders } from '../utils';

// Banner api 

export const getAllAddonList = () => {
  return axios.get(`${baseUrl}/addon/list`, { headers: getAuthHeaders() })
};

export const getSingleAddon = (id) => {
  return axios.get(`${baseUrl}/addon/${id}`, { headers: getAuthHeaders() })
};

export const createAddon = (data) => {
  return axios.post(`${baseUrl}/addon/create` , data, { headers: getAuthHeaders() })
};

export const deleteAddon = (id) => {
  return axios.delete(`${baseUrl}/addon/remove/${id}`, { headers: getAuthHeaders() })
};

export const updateAddon = (data) => {
  return axios.put(`${baseUrl}/addon/update`, data,{ headers: getAuthHeaders() })
};

export const uploadImage = (data, id) => {
  return axios.put(`${baseUrl}/upload/menu/addon?id=${id}`,data, { headers: getAuthHeaders() })
};

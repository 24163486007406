import axios from "axios";
import { baseUrl } from "../utils/urls";
import { getAuthHeaders } from "../utils";

// ShopMenu item api
export const getShopMenuList = (shopId) => {
  return axios.get(
    `${baseUrl}/shop/menu/${parseInt(shopId)}/0/${1}/${100}/list`,
    { headers: getAuthHeaders() }
  );
};

export const syncMenuItem = (shopId, itemId, seq) => {
  return axios.post(
    `${baseUrl}/menu/sync/shop/create`,
    { shop_id: shopId, item_id: itemId, seq },
    { headers: getAuthHeaders() }
  );
};

export const fullMasterMenuSync = (shopId) => {
  return axios.post(
    `${baseUrl}/menu/sync/shop/all`,
    { shop_id: shopId },
    { headers: getAuthHeaders() }
  );
};

export const unSyncMenuItem = (shopId, itemId) => {
  return axios.delete(`${baseUrl}/menu/sync/shop/remove/${shopId}/${itemId}`, {
    headers: getAuthHeaders(),
  });
};

export const searchShopMenu = (data) => {
  return axios.post(`${baseUrl}/menu/search`, data, {
    headers: getAuthHeaders(),
  });
};

export const getSingleMenuitemList = (shopId, itemId) => {
  return axios.get(`${baseUrl}/menu/sync/shop/get/${shopId}/${itemId}`, {
    headers: getAuthHeaders(),
  });
};

export const updateShopMenuItem = (data) => {
  return axios.put(`${baseUrl}/menu/sync/shop/put`, data, {
    headers: getAuthHeaders(),
  });
};

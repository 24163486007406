import React, { useState, useEffect } from 'react';
import Icon from "../../components/icon";
import { Col, FormGroup, Input, Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import {getAllVariation} from '../../services/Variation';
// import { getDetails } from '../../services/Banner'
import "./style.scss";
import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2';
import Select from "react-select";

const SyncVariation = ({  closeEdit, variationSelected,menuId,variationEdited,editVariation }) => {
    const [variation, setVariation] = useState();
    const [variations, setVariations] = useState();
    const [originalVariation, setOriginalVariation] = useState();
    const [price, setPrice] = useState(editVariation?editVariation.price:'');
    const [isDefault, setisDefault] = useState(editVariation?editVariation.default:false);

    const fetchAllVariation = () => {
        getAllVariation()
            .then((response) => {
                if (response.data.success&&response.data.data) {
                    let variationList = [];
                    response.data.data.variations.map(item=>{
                        variationList.push({label:item.variation_name,value:item.id})
                    });
                    setVariations(variationList)
                    setOriginalVariation(response.data.data.variations);
                } else {
                    console.log("Something went wrong");
                }
            })
    }

    useEffect(() => {
        fetchAllVariation()
    }, [])

  //Posting the data via model
  const selectVariation = async (variation) => {
    setVariation(variation);
  }

  const customStyles = {
    control: ( css, state ) => {
        return {
            ...css,
            borderColor: state.isFocused ? 'rgba(114, 94, 195, 0.6)' : '#eaecf0',
            '&:hover': {
                borderColor: state.isFocused ? 'rgba(114, 94, 195, 0.6)' : '#eaecf0',
            },
            boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(114, 94, 195, 0.2)' : '',
        };
    },
    option: ( css, state ) => {
        let bgc = '';
  
        if ( state.isSelected ) {
            bgc = '#725ec3';
        } else if ( state.isFocused ) {
            bgc = 'rgba(114, 94, 195, 0.2)';
        }
  
        return {
            ...css,
            backgroundColor: bgc,
        };
    },
}

  return (
    <React.Fragment>
      <Modal
        isOpen={true}
        fade
        on
      >
        <div className="modal-header">
          <h5 className="modal-title h2">{editVariation?'Edit Variation':'Select Variation'}</h5>
          <Button className="close" color="" onClick={() => { closeEdit({type:""})  }}>
            <Icon name="x" />
          </Button>
        </div>
        <ModalBody>
          
          <FormGroup>
       {!editVariation && <Col>
              <FormGroup>
              <div>Select Variation</div>
              <Select
                options={ variations }
                styles={ customStyles }
                placeholder="Variation"
                onChange={(e)=>{
                    selectVariation(e);
                }}
              />
          </FormGroup>
          </Col>}
          <Col>
          <FormGroup>
          <div>Variation Price</div>
            <Input
                id="nameInput"
                name="Price"
                placeholder="Amount in RM"
                type="numeric"
                required
                value = {price}
                onChange={(e)=> setPrice(e.target.value)}
              />
              </FormGroup>
            </Col>
            <Col>
            <FormGroup>
            <Col>
            <div>Is Default?</div>
              <Input
                  className='mb-10'
                  name="wireInput"
                  id="wireInput"
                  type="select"
                  onChange={(e)=>setisDefault(e.target.value)}
              >
                <option value="" disabled>Is Default?</option>
                <option value={true}> YES </option>
                <option value={false}>NO</option>
              
              </Input>
            </Col>
          </FormGroup>
            </Col>
            
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => {closeEdit({type:""}) }}>
            Close
          </Button>
         {!editVariation?<Button color="brand" disabled={!variation} onClick={()=>{
             const variationObj = originalVariation.find(item=>item.id===variation.value)
             variationSelected(menuId,variationObj,price,isDefault)}
             }>
              Add
          </Button>:<Button color="brand" disabled={!price} onClick={()=>{
             const variationObj = {variationId:editVariation.variationId,price:price,default:isDefault};
             variationEdited(menuId,variationObj);
             }
             }>
              Save
          </Button>}    
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}
export default SyncVariation;

/**
 * Internal Dependencies
 */

import AuthSignIn from './AuthSignIn';
import Coupon from './Coupon';
import Banner from './Banner'
import Addon from './Addon';
import Menu from './Menu';
import Shop from './Shop';
import ShopMenu from './ShopMenu';
import ShopRider from './ShopRider';
import ShopOrder from './ShopOrder';
import ShopDeliveryCharges from './ShopDeliveryCharges'
import Voucher from './Voucher';
import Category from './Category';
import FAQ from './FAQ';
import User from './User';
import Rider from './Rider';
import Campaign from './Campaign';
import Profile from './Profile';
import Variation from './Variation';
import Privacy from './Strings/Privacy';
import Terms from './Strings/Terms';
import MenuAddons from "./Menu/MenuAddons"
 import MenuVariations from "./Menu/MenuVariations"
 import Settings from "./Settings"
 import Packages from "./WalletPackages"
 import Birthday from "./Birthday Bonus"
export default {
    '/sign-in': AuthSignIn,
    '/coupon': Coupon,
    '/benner': Banner,
    '/category' : Category,
    '/campaign' : Campaign,
    '/addon' : Addon,
    '/voucher': Voucher,
    '/menu' : Menu,
    '/shop_menu/:id' : ShopMenu,
    '/shop_rider/:id': ShopRider,
    '/shop_order/:id' : ShopOrder,
    '/delivery_charge': ShopDeliveryCharges,
    '/users' : User,
    '/riders': Rider,
    '/' : Shop,
    '/faq' : FAQ,
    '/profile': Profile,
    '/variation': Variation,
    '/privacy': Privacy,
    '/terms': Terms,
    '/menu-addons':MenuAddons,
     "/menu-variations":MenuVariations,
     "/settings":Settings,
     "/wallet-packages":Packages,
     "/birthday-voucher":Birthday,
};

import axios from "axios";
import { baseUrl } from "../utils/urls";
import { getAuthHeaders } from '../utils';

// Survey api 
export const getAllVariation = (filter) => {
    return axios.get(`${baseUrl}/variation/list`, { headers: getAuthHeaders() });
};

export const getSingleVariation = (id) => {
    return axios.get(`${baseUrl}/variation/get/${id}`, { headers: getAuthHeaders() });
};

export const deleteVariation = (id) => {
    return axios.delete(`${baseUrl}/variation/remove/${id}`, { headers: getAuthHeaders() });
};

export const createVariation = (data) => {
    return axios.post(`${baseUrl}/variation/create`, data, { headers: getAuthHeaders() });
};

export const updateVariation = (data) => {
    return axios.put(`${baseUrl}/variation/update/${data.id}`, JSON.stringify(data), { headers: getAuthHeaders() });
};

import React, { useState, useEffect } from "react";
import Icon from "../../components/icon";
import {
  Col,
  FormGroup,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { getAllAddonList } from "../../services/Addon";
// import { getDetails } from '../../services/Banner'
import "./style.scss";
import AsyncSelect from "react-select/async";
import Swal from "sweetalert2";
import Select from "react-select";

const SyncAddon = ({ closeEdit, addonSelected, menuId }) => {
  const [addOn, setAddon] = useState();
  const [selectedAddon, setSelectedAddon] = useState([]);
  const [originalAddon, setOriginalAddon] = useState();
  const fetchAddonList = (value) => {
    getAllAddonList(value).then((response) => {
      if (response.data.success && response.data.data) {
        let addOnsList = [];
        response.data.data.map((item) => {
          addOnsList.push({ label: item.name, value: item.id });
        });
        setAddon(addOnsList);
        setOriginalAddon(response.data.data);
      } else {
        console.log("Something went wrong");
      }
    });
  };

  useEffect(() => {
    fetchAddonList("All");
  }, []);

  //Posting the data via model
  const selectAddOn = async (addOn) => {
    setSelectedAddon(addOn);
  };

  const customStyles = {
    control: (css, state) => {
      return {
        ...css,
        borderColor: state.isFocused ? "rgba(114, 94, 195, 0.6)" : "#eaecf0",
        "&:hover": {
          borderColor: state.isFocused ? "rgba(114, 94, 195, 0.6)" : "#eaecf0",
        },
        boxShadow: state.isFocused
          ? "0 0 0 0.2rem rgba(114, 94, 195, 0.2)"
          : "",
      };
    },
    option: (css, state) => {
      let bgc = "";

      if (state.isSelected) {
        bgc = "#725ec3";
      } else if (state.isFocused) {
        bgc = "rgba(114, 94, 195, 0.2)";
      }

      return {
        ...css,
        backgroundColor: bgc,
      };
    },
  };

  return (
    <React.Fragment>
      <Modal isOpen={true} fade on>
        <div className='modal-header'>
          <h5 className='modal-title h2'>{"Select Add On"}</h5>
          <Button
            className='close'
            color=''
            onClick={() => {
              closeEdit({ type: "" });
            }}
          >
            <Icon name='x' />
          </Button>
        </div>
        <ModalBody>
          <FormGroup>
            <Col>
              <FormGroup>
                <div>Select Add on</div>
                <Select
                  options={addOn}
                  styles={customStyles}
                  placeholder='Add On'
                  isMulti
                  closeMenuOnSelect={false}
                  onChange={selectAddOn}
                />
              </FormGroup>
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color='secondary'
            onClick={() => {
              closeEdit({ type: "" });
            }}
          >
            Close
          </Button>
          <Button
            color='brand'
            disabled={!selectedAddon}
            onClick={() => {
              let addonArray = [];
              selectedAddon.map((s) => {
                const addonObj = originalAddon.find(
                  (item) => item.id === s.value
                );
                addonArray.push(addonObj);
              });
              addonSelected(menuId, addonArray);
            }}
          >
            Add
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
export default SyncAddon;

import React, { useState, useEffect } from "react";
import Icon from "../../components/icon";
import {
  Col,
  FormGroup,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { getSingleMenu, updateMenu } from "../../services/Menu";
import "./style.scss";
import AsyncSelect from "react-select/async";
import Swal from "sweetalert2";
import { getAllFoodCategoryList } from "../../services/Category";
import Select from "react-select";
const EditJobCat = ({ id, closeEdit, editSuccess }) => {
  const [loading, setLoading] = useState(false);

  // menu data
  const [menu, setMenu] = useState([]);
  const [name, setName] = useState();
  const [desc, setDesc] = useState();
  const [sequence, setSequence] = useState();
  const [category_id, setCategory_id] = useState();
  const [image, setImage] = useState();
  const [isActive, setIsActive] = useState();
  const [categories, setCategories] = useState([]);
  const [categoryName, setCategoryName] = useState();
  const [selectedCategory, setSelectedCategory] = useState({});
  // base price
  const [basePrice, setBasePrice] = useState([]);
  const [Pickup, setPickup] = useState();
  const [DineIn, setDineIn] = useState();
  const [Delivery, setDelivery] = useState();
  const [Ecommerce, setEcommerce] = useState();
  const [Packaging, setPackaging] = useState();
  // Variations data
  const [variations, setVariations] = useState([]);
  const [variationPrice, setVariationPrice] = useState();
  const [variationId, setVariationId] = useState();
  const [variationDefault, setVariationDefault] = useState();

  //Addons data
  const [addons, setAddons] = useState([]);
  const [addonId, setAddonId] = useState();
  const [addonPrice, setAddonPrice] = useState();

  const fetchCategories = () => {
    getAllFoodCategoryList().then((response) => {
      if (response.data.success && response.data.data) {
        const categories = response.data.data;
        let categoriesList = [];
        categories.map((item) => {
          categoriesList.push({ label: item.name, value: item.id });
        });
        setCategories(categoriesList);
      }
    });
  };

  useEffect(() => {
    fetchCategories();
    fetchData(id);
  }, []);

  const customStyles = {
    control: (css, state) => {
      return {
        ...css,
        borderColor: state.isFocused ? "rgba(114, 94, 195, 0.6)" : "#eaecf0",
        "&:hover": {
          borderColor: state.isFocused ? "rgba(114, 94, 195, 0.6)" : "#eaecf0",
        },
        boxShadow: state.isFocused
          ? "0 0 0 0.2rem rgba(114, 94, 195, 0.2)"
          : "",
      };
    },
    option: (css, state) => {
      let bgc = "";

      if (state.isSelected) {
        bgc = "#725ec3";
      } else if (state.isFocused) {
        bgc = "rgba(114, 94, 195, 0.2)";
      }

      return {
        ...css,
        backgroundColor: bgc,
      };
    },
    multiValueLabel: (css) => {
      return {
        ...css,
        color: "#545b61",
        backgroundColor: "#eeeeef",
      };
    },
  };
  //update Job data
  const EditJob = async () => {
    const data = {
      name: name,
      desc: desc,
      basePrice: {
        Pickup: parseFloat(Pickup),
        "Dine-In": parseFloat(DineIn),
        Delivery: parseFloat(Delivery),
        "E-commerce": 0,
        Packaging: parseFloat(Packaging),
      },
      isEcommerce: "",
      category_id: selectedCategory.value,
      isActive: isActive,
      seq: parseInt(sequence),
    };

    setLoading(true);
    updateMenu(JSON.stringify(data), id).then((response) => {
      if (response.data.success) {
        Swal.fire({
          title: "Success",
          text: response.data.data.message
            ? response.data.data.message
            : "Something went wrong",
          icon: "success",
          confirmButtonText: "Back",
        });
        closeEdit({ type: "" });
        editSuccess();
      } else {
        Swal.fire({
          title: "Error!",
          text: response.data.errorMessage
            ? response.data.errorMessage
            : "Something went wrong",
          icon: "error",
          confirmButtonText: "Back",
        });
        console.log("Soething went wrong");
        setLoading(false);
      }
    });
  };

  // handle input change event
  const handleInputChange = (value) => {
    setInputValue(value);
  };

  // handle selection
  const handleChange = (value) => {
    setSelectedValue(value);
  };

  // get details data
  const fetchData = (id) => {
    getSingleMenu(id).then((res) => {
      if (res.data.success) {
        const response = res.data.data;
        setMenu(response);
        setName(response.name);
        setDesc(response.desc || "");
        setCategory_id(response.category_id);
        setIsActive(response.isActive);
        setCategoryName(response.Category.name);
        // base price
        setPickup(response.basePrice.Pickup);
        setDineIn(response.basePrice["Dine-In"]);
        setDelivery(response.basePrice.Delivery);
        setPackaging(response.basePrice.Packaging);
        setBasePrice(response.basePrice);
        setSequence(response.seq || "");
      }
    });
  };

  return (
    <React.Fragment>
      <Modal isOpen={true} fade on>
        <div className='modal-header'>
          <h5 className='modal-title h2'>Edit Menu</h5>
          <Button
            className='close'
            color=''
            onClick={() => closeEdit({ type: "" })}
          >
            <Icon name='x' />
          </Button>
        </div>
        <ModalBody>
          <FormGroup>
            <Col>
              <FormGroup>
                <div>Menu Name</div>
                <Input
                  id='nameInput'
                  name='Name'
                  placeholder='Name'
                  type='text'
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <div>Description</div>
                <Input
                  className='mb-10'
                  type='text'
                  id='field11'
                  placeholder='Description'
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <div>Sequence</div>
                <Input
                  className='mb-10'
                  type='number'
                  id='field5'
                  placeholder='Sequence in number'
                  value={sequence}
                  onChange={(e) => setSequence(e.target.value)}
                />
              </FormGroup>
            </Col>
          </FormGroup>
          <FormGroup>
            <Col>
              <FormGroup>
                <div>Status</div>
                <Input
                  className='mb-10'
                  name='wireInput'
                  id='wireInput'
                  type='select'
                  value={isActive}
                  onChange={(e) => setIsActive(e.target.value)}
                >
                  <option value='' disabled selected>
                    {" "}
                    Status{" "}
                  </option>
                  <option value='true'> Active </option>
                  <option value='false'> Inactive </option>
                </Input>
              </FormGroup>

              {/* <AsyncSelect
                isMulti
                placeholder="Select Type"
                cacheOptions
                defaultOptions
                value={selectedValue}
                getOptionLabel={e => e.name}
                getOptionValue={e => e.id}
                loadOptions={fetchData}
                onInputChange={handleInputChange}
                onChange={handleChange}
              /> */}
            </Col>
          </FormGroup>
          <FormGroup>
            <div>Selected Category</div>
            <Input
              className='mb-10'
              type='text'
              id='field11'
              placeholder='Category Name'
              value={categoryName}
            />
          </FormGroup>
          {/* <h3> Coupon Property</h3> */}
          <FormGroup>
            {/* <label id='field1' > Start Date</label> */}
            <FormGroup>
              <div>Update Category</div>
              <Select
                options={categories}
                styles={customStyles}
                placeholder='Select Category'
                onChange={(e) => {
                  debugger;
                  setCategoryName(e.label);
                  setSelectedCategory(e);
                }}
              />
            </FormGroup>

            <h3> Base Price </h3>
            <div>Pickup Price</div>
            <Input
              className='mb-10'
              type='number'
              id='field2'
              placeholder='Amount in RM'
              value={Pickup}
              onChange={(e) => setPickup(e.target.value)}
            />
            <div>Dine-In Price</div>
            <Input
              className='mb-10'
              type='number'
              id='field3'
              placeholder='Amount in RM'
              value={DineIn}
              onChange={(e) => setDineIn(e.target.value)}
            />
            <div>Delivery Price</div>
            <Input
              className='mb-10'
              type='number'
              id='field4'
              placeholder='Amount in RM'
              value={Delivery}
              onChange={(e) => setDelivery(e.target.value)}
            />
            <div>Packaging Price</div>
            <Input
              className='mb-10'
              type='number'
              id='field5'
              placeholder='Amount in RM'
              value={Packaging}
              onChange={(e) => setPackaging(e.target.value)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color='secondary'
            onClick={() => {
              closeEdit({ type: "" });
            }}
          >
            Close
          </Button>
          <Button color='brand' onClick={EditJob}>
            {loading ? (
              <div className='spinner-border spinner-fix' role='status'>
                <span className='visually-hidden'></span>
              </div>
            ) : (
              "Update"
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
export default EditJobCat;

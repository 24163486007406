/**
 * External Dependencies
 */
import React, { Component } from 'react';

/**
 * Internal Dependencies
 */
import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import { useLocation } from 'react-router-dom';

const ShopMenu = () =>{
    const search = useLocation().search;
    const shopName = new URLSearchParams(search).get('shop');
        return (
            <PageWrap>
                <PageTitle
                >
                    <h1> Shop Menu ({shopName})</h1>
                </PageTitle>
                <PageContent>
                    <AsyncComponent component={() => import('./content')} />
                </PageContent>
            </PageWrap>
        );
}

export default ShopMenu;
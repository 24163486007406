import axios from "axios";
import { baseUrl } from "../utils/urls";
import { getAuthHeaders } from '../utils';

// Job api 
export const getMenuList = (filter) => {
    return axios.get(`${baseUrl}/menu/list?page=1&limit=200&filter=${filter}`, { headers: getAuthHeaders() });
};
export const getSingleMenu = (id) => {
    return axios.get(`${baseUrl}/menu/get/${id}`, { headers: getAuthHeaders() });
};

export const getSingleShopMenu = (shopId,menuId) => {
    return axios.get(`${baseUrl}/menu/sync/shop/get/${shopId}/${menuId}`, { headers: getAuthHeaders() });
};

export const createMenu = (data) => {
    return axios.post(`${baseUrl}/menu/create`, data, { headers: getAuthHeaders() });
};

export const updateMenu = (data, id) => {
    return axios.put(`${baseUrl}/menu/update/${id}`, data, { headers: getAuthHeaders() });
};

export const deleteMenu = (id) => {
    return axios.delete(`${baseUrl}/menu/delete/${id}`, { headers: getAuthHeaders() });
};

export const uploadImage = (data, id) => {
    return axios.put(`${baseUrl}/upload/menu/item?id=${id}`, data, { headers: getAuthHeaders() });
};

export const searchMenu = (data) => {
    return axios.post(`${baseUrl}/menu/search`, data, { headers: getAuthHeaders() });
};





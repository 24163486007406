import React, {Fragment, useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button, Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { createTerms,getTerms } from '../../services/Strings';
import Swal from 'sweetalert2';
import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import "./style.scss";
const Terms = () => {

  const [terms, setTerms] = useState({
    data: "",
  });

  const fetchTerms = (value) => {
    getTerms()
        .then((response) => {
            if (response.data.success) {
                setTerms({data:response.data.data.meta})
            } else {
                console.log("Something went wrong");
            }
        })
}

  useEffect(() => {
    document.title = "MDT | Terms & Conditions";
    fetchTerms();
  }, []);

  const _updateTerms = (e) => {
    e.preventDefault();

    createTerms(terms).then(response=>{
        if (response.data.success) {
            Swal.fire({
                title: 'Success',
                text: `Terms & Conditions updated successfully.`,
                icon: 'success',
                confirmButtonText: 'OK'
              })
        } else {
            console.log("Something went wrong");
        }
    })
  };
  return (
    <PageWrap>
    <PageTitle>
    <h1> Terms & Conditions </h1>
    </PageTitle>
    <PageContent>
    <Fragment>
      <div className="rui-filemanager">
      <div className="rui-filemanager-head">
                    <div className="row sm-gap vertical-gap align-items-center">
                        <div className="col">
                            
                        </div>
                        <div className="col-auto">
                        <div className="input-group-prepend mnl-3">
                            <button
                                onClick={_updateTerms}
                                type="button"
                                className="btn btn-brand btn-sm btn-uniform job-btn-fix"
                            >Save
                            </button> 
                            </div>
                        </div>
                    </div>
                </div>
            <ReactQuill
              theme="snow"
              value={terms.data || " "}
              onChange={(value) =>
              {
                setTerms({
                  data: value,
                })
              }
              }
            />
            </div></Fragment>
                </PageContent>
            </PageWrap>
  );
};

export default Terms;

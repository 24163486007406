/* eslint-disable react/display-name*/
import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import DataTable from "react-data-table-component";
import Icon from "../../components/icon";
import "./style.scss";
import { updateMenu, getSingleMenu } from "../../services/Menu";
import CouponCreate from "./CreateMenu";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment";
import EditCoupon from "./EditMenu";
import SyncAddon from "./SyncAddon";
import Swal from "sweetalert2";
import { baseUrl } from "../../utils/urls";
import { useLocation } from "react-router-dom";
import PageWrap from "../../components/page-wrap";
import {
  getSingleMenuitemList,
  updateShopMenuItem,
} from "../../services/ShopMenu";

const MenuAddons = () => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();
  const [shopId, setShopId] = useState(query.get("shopId"));
  const [type, setType] = useState(query.get("type"));
  const [menuId, setMenuId] = useState(query.get("menuId"));
  const [shopName, setShopName] = useState(query.get("shop"));
  const [menu, setMenu] = useState();
  const [addOns, setAddOns] = useState([]);
  const [showDialog, setshowDialog] = useState({ type: "", id: "" });
  const [loading, setLoading] = useState(true);

  // Update menu add ons
  const updateAddOns = (menuD) => {
    if (type === "master") {
      setLoading(true);
      updateMenu(menuD, menuId).then((response) => {
        setLoading(false);
        if (response.data.success) {
          Swal.fire({
            title: "Success",
            text: response.data.data.message
              ? response.data.data.message
              : "Something went wrong",
            icon: "success",
            confirmButtonText: "Back",
          });

          fetchData(menuId);
        } else {
          Swal.fire({
            title: "Error!",
            text: response.data.errorMessage
              ? response.data.errorMessage
              : "Something went wrong",
            icon: "error",
            confirmButtonText: "Back",
          });
        }
      });
      setshowDialog({ type: "" });
    } else if (type === "shop") {
      setLoading(true);
      menuD.shop_id = shopId;
      menuD.item_id = menuId;
      updateShopMenuItem(JSON.stringify(menuD)).then((response) => {
        setLoading(false);
        if (response.data.success) {
          Swal.fire({
            title: "Success",
            text: response.data.data.message
              ? response.data.data.message
              : "Something went wrong",
            icon: "success",
            confirmButtonText: "Back",
          });
          fetchData(menuId);
        } else {
          Swal.fire({
            title: "Error!",
            text: response.data.errorMessage
              ? response.data.errorMessage
              : "Something went wrong",
            icon: "error",
            confirmButtonText: "Back",
          });
          console.log("Soething went wrong");
        }
      });
      setshowDialog({ type: "" });
    }
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.addonId,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Delivery Price",
      selector: (row) => `RM ${row.price.Delivery}`,
      sortable: true,
    },
    {
      name: "Pickup Price",
      selector: (row) => `RM ${row.price.Pickup}`,
      sortable: true,
    },
    {
      name: "Dine-In Price",
      selector: (row) => `RM ${row.price["Dine-In"]}`,
      sortable: true,
    },

    {
      name: "Action",
      selector: (row) => row.action,
      cell: (value) => (
        <>
          <button
            title='Delete Add on'
            type='button'
            className='btn btn-brand btn-sm btn-uniform btn-round btn-fix rui-filemanager-file-button'
            onClick={() => {
              setshowDialog({ type: "deleteItem", id: value.id });
            }}
          >
            <Icon name='trash' />
          </button>
        </>
      ),
    },
  ];

  // get details data
  const fetchData = (id) => {
    setLoading(true);
    if (type === "master") {
      // to get details master menu item
      getSingleMenu(id).then((res) => {
        setLoading(false);
        if (res.data.success) {
          const response = res.data.data;
          setMenu(response);
          const addons = [];
          response.MasterMenuAddons.map((item) => {
            addons.push({
              addonId: item.addon_id,
              price: item.price,
              name: item.Addon.name,
            });
          });
          setAddOns(addons);
        }
      });
    } else if (type === "shop") {
      // to get details shop menu item
      getSingleMenuitemList(shopId, id).then((res) => {
        setLoading(false);
        if (res.data.success) {
          const response = res.data.data.item;
          setMenu(response);
          const addons = [];
          response.ShopMenuAddons.map((item) => {
            addons.push({
              addonId: item.addon_id,
              price: item.price,
              name: item.Addon.name,
            });
          });
          setAddOns(addons);
        }
      });
    }
  };

  useEffect(() => {
    fetchData(menuId);
  }, [menuId]);

  const addonSelected = (menuId, addonSelected) => {
    let allAddons = addOns;
    addonSelected.map((add) => {
      const existIndex = allAddons.findIndex((item) => item.addonId === add.id);
      if (existIndex !== -1) {
        return;
      }
      allAddons.push({
        addonId: add.id,
        price: {
          Delivery: add.price ? add.price.Delivery : 0,
          Pickup: add.price ? add.price.Pickup : 0,
          "Dine-In": add.price ? add.price["Dine-In"] : 0,
        },
      });
    });

    setAddOns(allAddons);
    let menuD = {};
    menuD.addons = allAddons;
    updateAddOns(menuD);
  };

  const handleDeleteAddon = (addonId) => {
    let allAddons = addOns;
    const indexToDelete = allAddons.findIndex((item) => item.id === addonId);
    if (indexToDelete === -1) {
      return;
    }
    allAddons.splice(indexToDelete, 1);
    setAddOns(allAddons);
    let menuD = {};
    menuD.addons = allAddons;
    updateAddOns(menuD);
  };

  const handleToggleDelete = () => {
    setshowDialog({ type: "" });
  };

  return (
    <PageWrap>
      <Fragment>
        <div className='rui-filemanager job-section'>
          <div className='rui-filemanager-head'>
            <div className='row sm-gap vertical-gap align-items-center'>
              <div className='col'>
                <div className='input-group'>
                  <h1>Menu Add ons</h1>
                </div>
              </div>
              <div className='col-auto'>
                <button
                  title='Add Menu'
                  type='button'
                  className='btn btn-brand btn-sm btn-uniform btn-round'
                  onClick={() => {
                    setshowDialog({ type: "addItem" });
                  }}
                >
                  <Icon name='plus' />
                </button>
              </div>
            </div>
          </div>
          <div className='rui-filemanager-content'>
            <div className='table-responsive-lg mb-20 inner-content-fix'>
              {addOns.length === 0 && loading ? (
                <div className='spinner-border text-primary m-10' role='status'>
                  <span className='visually-hidden'></span>
                </div>
              ) : addOns.length === 0 && !loading ? (
                <div className='text-primary m-10' role='status'>
                  <span className='visually-hidden'>No add ons here</span>
                </div>
              ) : (
                <DataTable
                  className='rui-datatable rui-filemanager-table table mb-20 mt-10'
                  columns={columns}
                  data={addOns}
                  key={addOns.id}
                  persistTableHead
                  pagination={true}
                  highlightOnHover={true}
                  onChangePage={(page, totalRows) => {
                    debugger;
                  }}
                  paginationPerPage={10}
                  paginationTotalRows={addOns.length}
                />
              )}
            </div>
          </div>
          {showDialog.type == "deleteItem" && (
            <Modal
              isOpen={showDialog}
              toggle={handleToggleDelete}
              fade
              id={showDialog.id}
            >
              <div className='modal-header'>
                <h5 className='modal-title h2'>Delete</h5>
                <Button className='close' color='' onClick={handleToggleDelete}>
                  <Icon name='x' />
                </Button>
              </div>
              <ModalBody>
                <h1>Are you sure to delete this menu add on?</h1>
              </ModalBody>
              <ModalFooter>
                <Button color='secondary' onClick={handleToggleDelete}>
                  Close
                </Button>
                <Button
                  color='brand'
                  onClick={() => {
                    handleDeleteAddon(showDialog.id);
                  }}
                >
                  Delete
                </Button>
              </ModalFooter>
            </Modal>
          )}
          {showDialog.type === "addItem" && (
            <SyncAddon
              closeEdit={setshowDialog}
              addonSelected={addonSelected}
              menuId={menuId}
            />
          )}
        </div>
      </Fragment>
    </PageWrap>
  );
};

export default MenuAddons;

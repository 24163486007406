/* eslint-disable react/display-name*/
import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import DataTable from "react-data-table-component";
import Icon from "../../components/icon";
import "./style.scss";
import { updateMenu, getSingleMenu } from "../../services/Menu";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import SyncVariation from "./SyncVariation";
import PageTitle from "../../components/page-title";
import PageWrap from "../../components/page-wrap";
import {
  getSingleMenuitemList,
  updateShopMenuItem,
} from "../../services/ShopMenu";

const MenuVariations = () => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();
  const [shopId, setShopId] = useState(query.get("shopId"));
  const [type, setType] = useState(query.get("type"));
  const [menuId, setMenuId] = useState(query.get("menuId"));
  const [menu, setMenu] = useState();
  const [variations, setVariations] = useState([]);
  const [showDialog, setshowDialog] = useState({ type: "", id: "" });
  const [loading, setLoading] = useState(true);

  // Update menu variations
  const updateVariations = (menuD) => {
    if (type === "master") {
      setLoading(true);
      updateMenu(menuD, menuId).then((response) => {
        setLoading(false);
        if (response.data.success) {
          Swal.fire({
            title: "Success",
            text: response.data.data.message
              ? response.data.data.message
              : "Something went wrong",
            icon: "success",
            confirmButtonText: "Back",
          });
          fetchData(menuId);
        } else {
          Swal.fire({
            title: "Error!",
            text: response.data.errorMessage
              ? response.data.errorMessage
              : "Something went wrong",
            icon: "error",
            confirmButtonText: "Back",
          });
        }
      });
      setshowDialog({ type: "" });
    } else if (type === "shop") {
      setLoading(true);
      menuD.shop_id = shopId;
      menuD.item_id = menuId;
      updateShopMenuItem(JSON.stringify(menuD), menuId).then((response) => {
        setLoading(false);
        if (response.data.success) {
          Swal.fire({
            title: "Success",
            text: response.data.data.message
              ? response.data.data.message
              : "Something went wrong",
            icon: "success",
            confirmButtonText: "Back",
          });
          fetchData(menuId);
        } else {
          Swal.fire({
            title: "Error!",
            text: response.data.errorMessage
              ? response.data.errorMessage
              : "Something went wrong",
            icon: "error",
            confirmButtonText: "Back",
          });
          console.log("Soething went wrong");
        }
      });
      setshowDialog({ type: "" });
    }
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.variationId,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: false,
    },
    {
      name: "Price",
      selector: (row) => `RM ${row.price}`,
      sortable: false,
    },
    {
      name: "Default",
      selector: (row) => `${row.default ? "YES" : "NO"}`,
      sortable: false,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      cell: (value) => (
        <>
          <button
            title='Edit Variation'
            type='button'
            className='btn btn-brand btn-sm btn-uniform btn-round btn-fix rui-filemanager-file-button'
            onClick={() => {
              debugger;
              setshowDialog({ type: "editItem", variation: value });
            }}
          >
            <Icon name='edit' />
          </button>

          <button
            title='Delete Variation'
            type='button'
            className='btn btn-brand btn-sm btn-uniform btn-round btn-fix rui-filemanager-file-button'
            onClick={() => {
              setshowDialog({ type: "deleteItem", id: value.variationId });
            }}
          >
            <Icon name='trash' />
          </button>
        </>
      ),
    },
  ];

  // get details data
  const fetchData = (id) => {
    if (type === "master") {
      // to get details master menu item
      setLoading(true);
      getSingleMenu(id).then((res) => {
        setLoading(false);
        if (res.data.success) {
          const response = res.data.data;
          setMenu(response);
          const variations = [];
          response.MasterMenuVariations.map((item) => {
            variations.push({
              variationId: item.variation_id,
              price: item.price,
              name: item.Variation.variation_name,
              default: item.default,
            });
          });
          setVariations(variations);
        }
      });
    } else if (type === "shop") {
      // to get details shop menu item
      getSingleMenuitemList(shopId, id).then((res) => {
        setLoading(false);
        if (res.data.success) {
          const response = res.data.data.item;
          setMenu(response);
          const variations = [];
          response.ShopMenuVariations.map((item) => {
            variations.push({
              variationId: item.variation_id,
              price: item.price,
              name: item.Variation.variation_name,
              default: item.isDefault,
            });
          });
          setVariations(variations);
        }
      });
    }
  };

  useEffect(() => {
    fetchData(menuId);
  }, [menuId]);

  const variationSelected = (menuId, variationSelected, price, isDefault) => {
    let allVariations = variations;
    const existIndex = allVariations.findIndex(
      (item) => item.variationId === variationSelected.id
    );
    if (existIndex !== -1) {
      Swal.fire({
        title: "Error",
        text: "Variation already exist",
        icon: "error",
        confirmButtonText: "Back",
      });
      return;
    }
    allVariations.push({
      variationId: variationSelected.id,
      price: parseInt(price),
      default: isDefault,
    });
    setVariations(allVariations);
    let menuD = {};
    menuD.variations = allVariations;
    updateVariations(menuD);
  };

  const variationEdited = (menuId, variationEdited) => {
    let allVariations = variations;
    debugger;
    const varIndex = allVariations.findIndex(
      (item) => item.variationId === variationEdited.variationId
    );
    if (varIndex !== -1) {
      allVariations.splice(varIndex, 1, variationEdited);
    }
    setVariations(allVariations);
    let menuD = {};
    menuD.variations = allVariations;
    debugger;
    updateVariations(menuD);
  };

  const handleDeleteVariation = (variationId) => {
    let allVariations = variations;
    const indexToDelete = allVariations.findIndex(
      (item) => item.variationId === variationId
    );
    if (indexToDelete === -1) {
      return;
    }
    allVariations.splice(indexToDelete, 1);
    setVariations(allVariations);
    let menuD = {};
    menuD.variations = allVariations;
    updateVariations(menuD);
  };

  const handleToggleDelete = () => {
    setshowDialog({ type: "" });
  };

  return (
    <PageWrap>
      <Fragment>
        <div className='rui-filemanager job-section'>
          <div className='rui-filemanager-head'>
            <div className='row sm-gap vertical-gap align-items-center'>
              <div className='col'>
                <div className='input-group'>
                  <h1> Menu Variations</h1>
                </div>
              </div>
              <div className='col-auto'>
                <button
                  title='Add Menu'
                  type='button'
                  className='btn btn-brand btn-sm btn-uniform btn-round'
                  onClick={() => {
                    setshowDialog({ type: "addItem" });
                  }}
                >
                  <Icon name='plus' />
                </button>
              </div>
            </div>
          </div>
          <div className='rui-filemanager-content'>
            <div className='table-responsive-lg mb-20 inner-content-fix'>
              {variations.length === 0 && loading ? (
                <div className='spinner-border text-primary m-10' role='status'>
                  <span className='visually-hidden'></span>
                </div>
              ) : variations.length === 0 && !loading ? (
                <div className='text-primary m-10' role='status'>
                  <span className='visually-hidden'>No variations here</span>
                </div>
              ) : (
                <DataTable
                  className='rui-datatable rui-filemanager-table table mb-20 mt-10'
                  columns={columns}
                  data={variations}
                  key={variations.id}
                  persistTableHead
                  pagination={true}
                  highlightOnHover={true}
                  onChangePage={(page, totalRows) => {
                    debugger;
                  }}
                  paginationPerPage={10}
                  paginationTotalRows={variations.length}
                />
              )}
            </div>
          </div>
          {showDialog.type == "deleteItem" && (
            <Modal
              isOpen={showDialog}
              toggle={handleToggleDelete}
              fade
              id={showDialog.id}
            >
              <div className='modal-header'>
                <h5 className='modal-title h2'>Delete</h5>
                <Button className='close' color='' onClick={handleToggleDelete}>
                  <Icon name='x' />
                </Button>
              </div>
              <ModalBody>
                <h1>Are you sure to delete this menu variation?</h1>
              </ModalBody>
              <ModalFooter>
                <Button color='secondary' onClick={handleToggleDelete}>
                  Close
                </Button>
                <Button
                  color='brand'
                  onClick={() => {
                    handleDeleteVariation(showDialog.id);
                  }}
                >
                  Delete
                </Button>
              </ModalFooter>
            </Modal>
          )}
          {showDialog.type === "editItem" && (
            <SyncVariation
              closeEdit={setshowDialog}
              variationEdited={variationEdited}
              menuId={menuId}
              editVariation={showDialog.variation}
            />
          )}
          {showDialog.type === "addItem" && (
            <SyncVariation
              closeEdit={setshowDialog}
              variationSelected={variationSelected}
              menuId={menuId}
            />
          )}
        </div>
      </Fragment>
    </PageWrap>
  );
};

export default MenuVariations;

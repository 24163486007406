import axios from "axios";
import { baseUrl } from "../utils/urls";
import { getAuthHeaders } from '../utils';

export const createTerms = (data) => {
    return axios.post(`${baseUrl}/meta/terms-of-use`, data, { headers: getAuthHeaders() });
};

export const createPrivacy = (data) => {
    return axios.post(`${baseUrl}/meta/privacy-policy`, JSON.stringify(data), { headers: getAuthHeaders() });
};

export const getTerms = () => {
    return axios.get(`${baseUrl}/meta/user/terms-of-use`, { headers: getAuthHeaders() });
};

export const getPrivacy = () => {
    return axios.get(`${baseUrl}/meta/user/privacy-policy`, { headers: getAuthHeaders() });
};
import axios from "axios";
import { baseUrl } from "../utils/urls";
import { getAuthHeaders } from '../utils';

// Banner api 

export const getAllFoodCategoryList = (type) => {
  return axios.get(`${baseUrl}/food/category/list?page=1&limit=100&filter=${type}`, { headers: getAuthHeaders() })
};

export const searchFoodCategory = (data) => {
  // data = {
  //   term: name,
  //   page: 1,
  //   limit: 20
  // }
  return axios.post(`${baseUrl}/food/category/search`, data, { headers: getAuthHeaders() })
};

export const getSingleFoodCategory = (id) => {
  return axios.get(`${baseUrl}/food/category/${id}`, { headers: getAuthHeaders() })
};

export const createFoodCategory = (data) => {
  return axios.post(`${baseUrl}/food/category/create` , data, { headers: getAuthHeaders() })
};


export const deleteFoodCategory = (id) => {
  return axios.delete(`${baseUrl}/food/category/delete/${id}`, { headers: getAuthHeaders() })
};

export const updateFoodCategory = (data) => {
  return axios.put(`${baseUrl}/food/category/update`, data,{ headers: getAuthHeaders() })
};

export const uploadImage = (data, id) => {
  return axios.put(`${baseUrl}/upload/menu/category?id=${id}`,data, { headers: getAuthHeaders() })
};


